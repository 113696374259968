import { memo, useCallback, useMemo, useState } from 'react';
import {
  BoldExtension,
  HeadingExtension,
  ItalicExtension,
  MentionAtomExtension,
  UnderlineExtension,
  PlaceholderExtension,
  ImageExtension,
  DropCursorExtension,
  TaskListExtension,
  EmojiExtension,
  TableExtension,
  BulletListExtension,
  HardBreakExtension,
  OrderedListExtension,
  LinkExtension,
} from 'remirror/extensions';
import { CountExtension } from '@remirror/extension-count';
import {
  ThemeProvider,
  Remirror,
  useRemirror,
  Toolbar,
  ToggleBoldButton,
  ToggleItalicButton,
  UndoButton,
  RedoButton,
  VerticalDivider,
  ToggleUnderlineButton,
  ToggleBulletListButton,
  ToggleOrderedListButton,
  ToggleTaskListButton,
  CreateTableButton,
} from '@remirror/react';
import { AllStyledComponent } from '@remirror/styles/emotion';

import EditorComponent from './EditorComponent';

import { translate } from 'utils/translations';

import './style.scss';
import { Workflow } from '@contractool/schema/Workflow';
import { InvalidContentHandler } from 'remirror';
import LinkButton from './LinkButton';
import { replaceNullTextValuesImmutable } from 'utils/general';

type Props = {
  type: 'input' | 'textarea' | 'wysiwyg';
  onSubmit: (json: any) => void;
  onChange?: (json: any) => void;
  value?: any;
  hideButton?: boolean;
  workflow?: Workflow;
  disableMentions?: boolean;
  htmlOutput?: boolean;
  placeholder?: string;
};



const RemirrorEditor: React.FC<Props> = ({
  type,
  onSubmit,
  onChange,
  value,
  hideButton,
  workflow,
  disableMentions,
  htmlOutput,
  placeholder,
}) => {
  const [inputFocused, setInputFocused] = useState<boolean>(false);
  const normalizedValue = useMemo(() => replaceNullTextValuesImmutable(value), [value]);

  const onError: InvalidContentHandler = useCallback(
    ({ json, invalidContent, transformers }) => {
      // Automatically remove all invalid nodes and marks.
      console.log('InvalidContentHandler', json, invalidContent);
	  return replaceNullTextValuesImmutable(json);
    },
    [],
  );
  const { manager, state } = useRemirror({
    extensions: () => [
      new HeadingExtension({ levels: [1, 2, 3] }),
      new BoldExtension({ weight: 'bold' }),
      new ItalicExtension(),
      new UnderlineExtension(),
      new TaskListExtension(),
      new BulletListExtension({}),
      new OrderedListExtension(),
      new TableExtension(),
      new LinkExtension({
        autoLink: true,
        selectTextOnClick: true,
        defaultTarget: '_blank',
      }),
      new EmojiExtension({ supportedLanguages: ['typescript', 'jsx'] }),
      new PlaceholderExtension({
        placeholder: placeholder
          ? placeholder
          : translate('Type here, use @ to mention somebody or # to tag'),
      }),
      new MentionAtomExtension({
        matchers: [
          {
            name: 'at',
            char: '@',
            matchOffset: 0,
            mentionClassName: 'text-gray-700, font-bold',
          },
          {
            name: 'hash',
            char: '#',
            matchOffset: 0,
            mentionClassName: 'text-gray-700, font-bold',
          },
        ],
      }),
      new CountExtension({}),
      new ImageExtension({ enableResizing: true }),
      new DropCursorExtension(),
      new BulletListExtension({}),
      new OrderedListExtension(),

      /**
       * `HardBreakExtension` allows us to create a newline inside paragraphs .
       *  e.g. in a list item
       */
      new HardBreakExtension(),
    ],
    content: normalizedValue,
    selection: 'end',
    stringHandler: 'html',
    onError,
  });

  const typeClass = {
    input: 'input',
    textarea: 'textarea',
  };

  const onFocus = useCallback(() => {
    setInputFocused(true);
  }, []);

  return (
    <AllStyledComponent>
      <ThemeProvider>
        <Remirror
          manager={manager}
          initialContent={state}
          classNames={[typeClass[type]]}
          onChange={(parameter) => {
            if (htmlOutput) {
              onChange?.(parameter.helpers.getHTML());
            } else {
              onChange?.(parameter.helpers.getJSON());
            }
          }}
          onFocus={onFocus}
        >
          <Toolbar>
            <UndoButton />
            <RedoButton />
            <VerticalDivider />

            <ToggleBoldButton />
            <ToggleItalicButton />
            <ToggleUnderlineButton />
            <VerticalDivider />

            <ToggleBulletListButton />
            <ToggleOrderedListButton />
            <ToggleTaskListButton />

            <CreateTableButton />
            {/* <LinkButton /> */}
          </Toolbar>
          <EditorComponent
            hideButton={hideButton}
            onSubmit={onSubmit}
            isFocused={inputFocused}
            workflow={workflow}
            type={type}
            disableMentions={disableMentions}
          />
        </Remirror>
      </ThemeProvider>
    </AllStyledComponent>
  );
};

export default memo(RemirrorEditor);
