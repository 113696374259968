import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import * as qs from 'qs';
import _, { debounce, isEmpty, set } from 'lodash';
import Select, {
  ClearIndicatorProps,
  components,
  DropdownIndicatorProps,
  GroupBase,
  MenuPlacement,
  OptionsOrGroups,
  PropsValue,
} from 'react-select';
import AsyncSelect from 'react-select/async';

import { Option } from 'components/Dropdown';
import { Icon } from 'components/Icon';

import { FormContext } from './Form';
import { transformObjects } from 'utils/transformOptionToValue';
import { http } from 'utils/http';
import { translate } from 'utils/translations';
import { useEffectAfterMount } from 'hooks/useEffectAfterMount';
import { usePrevious } from '@uidotdev/usehooks';

type Props = {
  // onChange could be a function that takes a result of type Option<string>[] or Option<string>
  onChange?: (result: Option<string>[] | Option<string>) => void;
  onBlur?: (e: any) => void;
  value?: PropsValue<string | Option<string>> | undefined;
  options?:
    | OptionsOrGroups<string | Option<string>, GroupBase<any>>
    | undefined;
  isMulti?: boolean;
  params?: object | null;
  apiParams?: any | null;
  readOnly?: boolean;
  isClearable?: boolean;
  hasError?: boolean;
  name?: string;
  isSearchable?: boolean;
  menuPortalTarget?: boolean;
  className?: string;
  defaultValue?: string;
  api?: string;
  menuPlacement?: MenuPlacement;
  preselectsFirstOption?: boolean;
  id?: string;
  placeholder?: string;
  resetValue?: any;
  openMenuOnFocus?: boolean;
  ref?: any;
};

const MultiselectDropdown2: React.FC<Props> = forwardRef(function MultiselectDropdown2({
  onChange,
  onBlur,
  value,
  options,
  isMulti = true,
  params,
  apiParams,
  readOnly,
  isClearable = true,
  hasError,
  name,
  isSearchable,
  menuPortalTarget,
  className,
  defaultValue,
  api,
  menuPlacement,
  id,
  placeholder,
  resetValue,
  preselectsFirstOption,
  openMenuOnFocus,
  ...props
}, ref) {
  const styles = {
    control: (base: any, state: any) => ({
      ...base,
      border: 'none',
      padding: '9px 0',
      borderRadius: '0',
      borderBottom: state.isFocused
        ? '1px solid #2684FF'
        : hasError
        ? '1px solid #f03e3e'
        : '1px solid #dee2e6',
      backgroundColor: 'transparent',
      boxShadow: 'none',
      cursor: 'pointer',
      ':hover': {
        ...base[':hover'],
        borderBottom: state.isFocused
          ? '1px solid #2684FF'
          : hasError
          ? '1px solid #f03e3e'
          : '1px solid #dee2e6',
      },
    }),
    menu: (base: any) => ({
      ...base,
      padding: '6px',
      margin: '0',
      borderRadius: '5px',
      boxShadow:
        '0 0 0 0px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%)',
    }),
    menuList: (base: any) => ({
      ...base,
      padding: '0',
    }),
    option: (base: any, state: any) => ({
      ...base,
      cursor: 'pointer',
      backgroundColor: state.isFocused ? '#f1f3f5' : 'white',
      borderRadius: '3px',
      color: state.isSelected ? '#2684FF' : 'inherit',
    }),
    indicatorSeparator: (base: any) => ({
      ...base,
      display: 'none',
    }),
    indicatorsContainer: (base: any) => ({
      ...base,
      '> div': {
        ...base['> div'],
        padding: '0 8px',
        color: 'inherit',
        ':hover': {
          ...base['> div:hover'],
          color: 'inherit',
        },
      },
    }),
    valueContainer: (base: any) => ({
      ...base,
      padding: '0',
    }),
  };

  const DropdownIndicator = (props: DropdownIndicatorProps<any, true>) => (
    <components.DropdownIndicator {...props}>
      <Icon
        name="triangle"
        className={`text-gray-600 ${props.isFocused ? '' : 'rotate-180'}`}
        size={2}
      />
    </components.DropdownIndicator>
  );

  const ClearIndicator = (props: ClearIndicatorProps<any, true>) => (
    <components.ClearIndicator {...props}>
      <Icon name="close" size={6} />
    </components.ClearIndicator>
  );

  if (api) {
    // TODO: From backend need to return {value: number, label: string}

    let newValue = value || [];
    // check if value is object
    if (
      Object.keys(newValue).length !== 0 &&
      typeof newValue === 'object' &&
      !Array.isArray(newValue)
    ) {
      // @ts-ignore
      if (value?.length > 0 && !value.label && !value.value) {
        // @ts-ignore
        newValue = { value: value.id, label: value.name };
        // @ts-ignore
      } else if (value && value.label && !value.value) {
        // @ts-ignore
        newValue = { value: value.id, label: value.label };
        // @ts-ignore
      } else if (value && !value.value && !value.label) {
        // @ts-ignore
        newValue = { value: value.id, label: value.name };
      }
    }

    const formContext = React.useContext(FormContext);
    const [state, setState] = useState({
      isLoading: false,
      options: [],
      value: newValue,
      page: 1,
      perPage: 20,
    });

    const handleChange = (value: any) => {
      onChange(value);
      setState((prevState) => ({
        ...prevState,
        value,
      }));
    };

    useEffect(() => {
      setState((prevState) => ({
        ...prevState,
        value: newValue,
      }));
    }, [value]);

    const getAsyncOptions = async (inputValue: string) => {
      const queryParams = isEmpty(params) ? '' : `&${qs.stringify(params)}`;

      const apiParamsCopy = { ...apiParams };
      if (apiParamsCopy && apiParamsCopy?.phrase?.length >= 0) {
        delete apiParamsCopy['phrase'];
      }

      const apiQueryParams = isEmpty(apiParamsCopy)
        ? ''
        : `&${qs.stringify(apiParamsCopy, {
            arrayFormat: 'brackets',
            encode: false,
          })}`;

      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));

      let urlParams = '';
      let operator = '?';

      if (api?.indexOf('?') > 0) {
        operator = '&';
      }

      urlParams =
        api +
        operator +
        `phrase=${encodeURIComponent(
          inputValue,
        )}${queryParams}${apiQueryParams}`;

      const optionsDirty = await http.get<any>(urlParams);

      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));

      // Check if from server comes data in format {data: []} or just []
      const options = transformObjects(optionsDirty?.['data'] || optionsDirty, {
        ...props,
      });

      const valueExists = await options?.find((option: Option<string>) => {
        if (typeof newValue === 'number') {
          return option.value === newValue;
        }
        const { value: id } = newValue as Option<string>;
        // check if newValue is Array of objects
        if (
          Array.isArray(newValue) &&
          newValue.length > 0 &&
          typeof newValue[0] === 'object'
        ) {
          return newValue.some((item) => item.value === option.value);
        }
        return option.value === id;
      });

      if (!isMulti) {
        if (valueExists && name) {
          set(formContext.values, name, valueExists);
          // handleChange(valueExists);
          setState((prevState) => ({
            ...prevState,
            value: valueExists,
          }));
        }
      }

      return options;
    };

    const loadOptions = useCallback(
      debounce((inputText, callback) => {
        getAsyncOptions(inputText).then((options) => callback(options));
      }, 300),
      [params],
    );

    const onMenuScrollToBottom = async () => {
      setState((prevState) => ({
        ...prevState,
        page: prevState.page + 1,
      }));
    };

    const handleFocus = async (e: any) => {
      const value = e.currentTarget.value;
      getAsyncOptions(value);
    };

    useEffect(() => {
      if (defaultValue) {
        getAsyncOptions(defaultValue);
      }
    }, [defaultValue]);

    useEffectAfterMount(() => {
      if (isMulti) {
        handleChange([]);
      } else {
        handleChange(null);
      }
      preselectFirstOptionAsync();
    }, [resetValue]);

    const preselectFirstOptionAsync = () => {
      getAsyncOptions('').then((options) => {
        if (preselectsFirstOption && options.length > 0) {
          handleChange(options[0]);
        }
      });
    };
    return (
      <AsyncSelect
        className="react-multiselectdropdown2 border-0"
        loadOptions={loadOptions}
        isMulti={isMulti}
        defaultOptions={true}
        defaultInputValue={defaultValue || ''}
        value={state.value}
        onChange={handleChange}
        inputId={name}
        onBlur={onBlur}
        key={params ? JSON.stringify(params) : name}
        isClearable={isClearable}
        placeholder={
          isMulti
            ? translate('Multiple selection option...')
            : translate('Select...')
        }
        isDisabled={readOnly}
        styles={styles}
        components={{ DropdownIndicator, ClearIndicator }}
        menuPlacement={menuPlacement || 'auto'}
        menuPortalTarget={menuPortalTarget ? document.body : null}
        isSearchable={isSearchable}
        isLoading={state.isLoading}
        onFocus={handleFocus}
        cacheOptions
        form="_none"
		openMenuOnFocus={openMenuOnFocus}
        onMenuScrollToBottom={onMenuScrollToBottom}
		ref={ref}
        {...props}
      />
    );
  }

  const [state, setState] = useState({
    isLoading: false,
    options: options,
    value: value,
  });

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      value: value,
    }));
  }, [value]);

  useEffect(() => {
    if (preselectsFirstOption) {
      if (options && options.length > 0) {
		if (isMulti) {
		  if (options[0]) {
			onChange([options[0]]);
		  }
		} else {
		  onChange(options[0]);
		}
	  }
    }
  }, []);

  return (
    <Select

      isMulti={isMulti === true ? true : undefined}
      options={options || []}
      value={state.value}
      onChange={(value: any) => onChange(value)}
      onBlur={onBlur}
      isClearable={isClearable}
      placeholder={
        placeholder
          ? placeholder
          : isMulti
          ? translate('Multiple selection option...')
          : translate('Select...')
      }
      isDisabled={readOnly}
      inputId={name}
      styles={styles}
      components={{ DropdownIndicator, ClearIndicator }}
      menuPlacement={menuPlacement || 'auto'}
      menuPortalTarget={menuPortalTarget ? document.body : null}
      isSearchable={isSearchable}
      className={'react-multiselectdropdown2 ' + className}
      openMenuOnFocus={openMenuOnFocus}
	  ref={ref}
      {...props}
    />
  );
});

export default MultiselectDropdown2;
